@import './fonts-and-colors.scss';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: $gb_col_2;
}

.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -75px;

  .temporarly-h1 {
    margin-top: 150px;
    color: white
  }
}

.plants {
  position: absolute;
  transform: rotateY(180deg);
  right: 0;
  bottom: 0;
  width: 400px
}

@import './navigation.css';
@import './home.css';

