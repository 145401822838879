@import url("https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap");
@import './navigation.css';
@import './home.css';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #29010c;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -75px;
}
.page .temporarly-h1 {
  margin-top: 150px;
  color: white;
}

.plants {
  position: absolute;
  transform: rotateY(180deg);
  right: 0;
  bottom: 0;
  width: 400px;
}/*# sourceMappingURL=App.css.map */